.fa-stack[data-count]:after {
    position: absolute;
    right: 0%;
    top: 1%;
    content: attr(data-count);
    font-size: 80%;
    padding: .2em;
    border-radius: 999px;
    line-height: .75em;
    color: white;
    background: rgba(255,0,0,.85);
    text-align: center;
    min-width: 1.5em;
    font-weight: bold;
}
